import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Menu from './Images/Menu.png';

import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";


function App() {
  
  return (
    <div class ="body" style={{justifyContent:'center', background:'black', flex:1}}>      
    {isMobile ?
    <img class = "body" src={Menu} width="100%" height="100%" /> 
    :
     <img src={Menu} width="800" height="1200" /> 
    }
 
    </div>
  );
}

export default App;
